<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                        <iq-card>
                            <template v-slot:headerTitle>
                                <h4 class="card-title">{{ $t('cotton_config.unit_assign') }} {{ $t('globalTrans.entry') }}</h4>
                            </template>
                            <template v-slot:headerAction>
                                <router-link to="unit-assign" class="btn btn_add_new">
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i> {{ $t('cotton_config.unit_assign') }} {{ $t('globalTrans.list') }}
                                </router-link>
                            </template>
                            <template v-slot:body>
                                <b-row>
                                    <b-col md="12" sm="12">
                                        <b-row>
                                            <b-col md="6">
                                                <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_type_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('globalTrans.office_type') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="formData.office_type_id"
                                                        :options="officeTypeList"
                                                        id="office_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col md="6">
                                                <ValidationProvider name="Office" vid="office_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('globalTrans.office') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="formData.office_id"
                                                        :options="officeList"
                                                        id="office_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col offset-md="3" md="9" class="text-right">
                                                <b-button type="button" variant="primary" class="mr-2" @click="getUnitAssignList">{{ $t('globalTrans.search') }}</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-overlay :show="loading">
                                    <b-table-simple hover small bordered responsive class="mt-4">
                                        <b-thead>
                                            <b-tr>
                                                <b-th width="7%" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                                <b-th class="text-center" width="23%">{{$t('cotton_config.name_mobile_email')}}</b-th>
                                                <b-th class="text-center" width="17%">{{$t('cotton_config.region')}}</b-th>
                                                <b-th class="text-center" width="18%">{{$t('cotton_config.zone')}}</b-th>
                                                <b-th class="text-center" width="17%">{{ $t('globalTrans.district') }}</b-th>
                                                <b-th class="text-center" width="18%">{{ $t('cotton_config.unit') }}</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <template v-if="formData.details && formData.details.length">
                                                <b-tr v-for="(item, index) in formData.details" :key="index">
                                                    <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                                    <b-td class="text-center">
                                                        <strong>{{ currentLocale === 'en' ? item.name_en : item.name_bn }}</strong><br>
                                                        <span>{{ $t('globalTrans.mobile') }}</span>: {{ item.mobile_no | mobileNumber }} <br/>
                                                        <span>{{ $t('globalTrans.email') }}</span>: {{ item.email }}
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <ValidationProvider name="Region" :vid="'details.' + index + 'region_id'" rules="required">
                                                            <div
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <v-select
                                                                    :id="'details.' + index + 'region_id'"
                                                                    v-model="item.region_ids"
                                                                    :reduce="op => op.value"
                                                                    multiple
                                                                    :options="item.regionList"
                                                                    label="text"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    @input="getZoneList(item)"
                                                                    :placeholder="$t('globalTrans.select')"
                                                                >
                                                                </v-select>
                                                                <div class="invalid-feedback d-block">
                                                                {{ errors[0] }}
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <ValidationProvider name="Zone" :vid="'details.' + index + 'zone_id'" :rules="zoneRules">
                                                            <div
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <v-select
                                                                    :id="'details.' + index + 'zone_id'"
                                                                    v-model="item.zone_ids"
                                                                    :reduce="op => op.value"
                                                                    multiple
                                                                    :options="item.zoneList"
                                                                    label="text"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    @input="getDistrictList(item)"
                                                                    :placeholder="$t('globalTrans.select')"
                                                                >
                                                                </v-select>
                                                                <div class="invalid-feedback d-block">
                                                                {{ errors[0] }}
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <ValidationProvider name="District" :vid="'details.' + index + 'district_id'" :rules="unitRules">
                                                            <div
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <v-select
                                                                    :id="'details.' + index + 'district_id'"
                                                                    v-model="item.district_ids"
                                                                    :reduce="op => op.value"
                                                                    multiple
                                                                    :options="item.districtList"
                                                                    label="text"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    @input="getUnitList(item)"
                                                                    :placeholder="$t('globalTrans.select')"
                                                                >
                                                                </v-select>
                                                                <div class="invalid-feedback d-block">
                                                                {{ errors[0] }}
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <ValidationProvider name="Unit" :vid="'details.' + index + 'unit_id'" :rules="unitRules">
                                                            <div
                                                                slot-scope="{ valid, errors }"
                                                                >
                                                                <v-select
                                                                    :id="'details.' + index + 'unit_id'"
                                                                    v-model="item.unit_ids"
                                                                    :reduce="op => op.value"
                                                                    multiple
                                                                    :options="item.unitList"
                                                                    label="text"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    :placeholder="$t('globalTrans.select')"
                                                                >
                                                                </v-select>
                                                                <div class="invalid-feedback d-block">
                                                                {{ errors[0] }}
                                                                </div>
                                                            </div>
                                                        </ValidationProvider>
                                                    </b-td>
                                                </b-tr>
                                            </template>
                                            <b-tr v-else>
                                                <b-td colspan="6" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-overlay>
                                <b-row>
                                    <b-col offset-md="3" md="9" class="text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$router.go(-1)">{{ $t('globalTrans.cancel') }}</b-button>
                                    </b-col>
                                </b-row>
                            </template>
                        </iq-card>
                    </b-form>
                </ValidationObserver>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl, agriMarketingServiceBaseUrl } from '@/config/api_config'
import { unitAssignStore, unitAssignList } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getResionData()
            this.formData = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                office_type_id: '0',
                office_id: '0',
                details: []
            },
            zoneList: [],
            unitList: [],
            checkAll: false,
            unitAssignList: [],
            officeList: [],
            zoneRules: null,
            unitRules: null
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        loading () {
            return this.$store.state.commonObj.loading
        },
        regionList () {
            return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
        },
        officeTypeList () {
            return this.$store.state.commonObj.officeTypeList.filter(item => item.org_id === 10 && item.status === 0 && item.text_en.toLowerCase() !== 'head office')
        }
    },
    watch: {
        'formData.office_type_id': function (newVal, oldVal) {
            if (newVal) {
                this.officeList = this.getOfficeList(newVal)
                // this.getUnitAssignList()
                const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(item => item.value === parseInt(newVal))
                if (officeTypeObj !== undefined) {
                    const officeTypeName = officeTypeObj.text_en.toLowerCase()
                    if (officeTypeName.includes('zonal')) {
                        this.zoneRules = 'required'
                        this.unitRules = null
                    } else if (officeTypeName.includes('unit')) {
                        this.zoneRules = 'required'
                        this.unitRules = 'required'
                    } else {
                        this.zoneRules = null
                        this.unitRules = null
                    }
                }
            } else {
                this.officeList = []
            }
        }
        // ,
        // 'formData.office_id': function () {
        //     this.getUnitAssignList()
        // }
    },
    methods: {
        async getUnitAssignList () {
            const data = this.formData.details
            delete data.details
            const params = Object.assign({}, data, { show_all: 1 })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            await RestApi.getData(agriMarketingServiceBaseUrl, unitAssignList, params).then(response => {
                if (response.success) {
                    this.unitAssignList = response.data
                    this.getUserList()
                } else {
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        },
        async getUserList () {
            const params = { office_type_id: this.formData.office_type_id, office_id: this.formData.office_id }
            const result = await RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', params)
            if (result.success) {
                const details = result.data.map(item => {
                    const unitAssignListItem = this.unitAssignList.find(obj => obj.user_id === item.user_id)
                    return Object.assign({}, {
                        regionList: this.regionList,
                        zoneList: unitAssignListItem !== undefined ? this.getZoneList(unitAssignListItem) : [],
                        districtList: unitAssignListItem !== undefined ? this.getDistrictList(unitAssignListItem) : [],
                        unitList: unitAssignListItem !== undefined ? this.getUnitList(unitAssignListItem) : [],
                        user_id: item.id,
                        office_type_id: item.office_type_id,
                        office_id: item.office_id,
                        designation_id: item.designation_id,
                        name_en: item.name,
                        name_bn: item.name_bn,
                        email: item.email,
                        mobile_no: item.mobile_no,
                        region_ids: unitAssignListItem !== undefined && unitAssignListItem.region_ids && Array.isArray(unitAssignListItem.region_ids) ? unitAssignListItem.region_ids : [],
                        zone_ids: unitAssignListItem !== undefined && unitAssignListItem.zone_ids && Array.isArray(unitAssignListItem.zone_ids) ? unitAssignListItem.zone_ids : [],
                        district_ids: unitAssignListItem !== undefined && unitAssignListItem.district_ids && Array.isArray(unitAssignListItem.district_ids) ? unitAssignListItem.district_ids : [],
                        unit_ids: unitAssignListItem !== undefined && unitAssignListItem.unit_ids && Array.isArray(unitAssignListItem.unit_ids) ? unitAssignListItem.unit_ids : []
                    })
                })
                this.formData = Object.assign(this.formData, { details: details })
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }

            const result = await RestApi.postData(agriMarketingServiceBaseUrl, unitAssignStore, this.formData)

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('agriMarketing/mutateAgriMarketingProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$router.push('unit-assign')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getOfficeList (id) {
            return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === parseInt(id) && item.status === 0)
        },
        getZoneList (detailItem) {
            const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1 && detailItem.region_ids.includes(item.region_id))
            detailItem.zoneList = zoneList
            // if region deleted, update selected zones accordingly
            if (detailItem.zone_ids && detailItem.zone_ids.length) {
                const zoneIdArr = zoneList.map(item => item.value)
                const selectedZoneIdArr = detailItem.zone_ids.filter(id => zoneIdArr.includes(id))
                detailItem.zone_ids = selectedZoneIdArr
                this.getDistrictList(detailItem)
            }
            return zoneList
        },
        getDistrictList (detailItem) {
            const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.status === 1 && detailItem.zone_ids && detailItem.zone_ids.includes(item.value))
            const districtIdArr = []
            zoneList.forEach(obj => {
                const idArr = obj.district_ids ? JSON.parse(obj.district_ids) : []
                // idArr.forEach(id => {
                //     districtIdArr.push(id)
                // })
                if (idArr.length) {
                    districtIdArr.push(...idArr)
                }
            })
            let list = []
            if (Array.isArray(districtIdArr)) {
                list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && districtIdArr.includes(parseInt(item.value)))
            }
            detailItem.districtList = list
            if (detailItem.district_ids && detailItem.district_ids.length) {
                const districtIdArr = list.map(item => item.value)
                const selectedIdArr = detailItem.district_ids.filter(id => districtIdArr.includes(id))
                detailItem.district_ids = selectedIdArr
                this.getUnitList(detailItem)
            }
            return list
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnitList (detailItem) {
            const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1 && detailItem.district_ids && detailItem.district_ids.includes(item.district_id))
            detailItem.unitList = unitList
            if (detailItem.unit_ids && detailItem.unit_ids.length) {
                const unitIdArr = unitList.map(item => item.value)
                const selectedIdArr = detailItem.unit_ids.filter(id => unitIdArr.includes(id))
                detailItem.unit_ids = selectedIdArr
            }
            return unitList
        },
        getDesignationName (id) {
            const obj = this.$store.state.commonObj.designationList.find(item => item.value === parseInt(id))
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        },
        getOfficeName (id) {
            const obj = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(id))
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        }
    }
}
</script>
